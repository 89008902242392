import { useContext } from 'react';

import { TranslationsContext } from '@/i18n/provider';

const Translate = ({
  replace,
  text,
  noSpan = false,
  Element = 'span',
}: {
  replace?: { symbol: string; string: any }[];
  text: string;
  noSpan?: boolean;
  Element?: any;
}) => {

  const transContext = useContext(TranslationsContext);

  const currentTrans = transContext?.translations?.current;
  const defaultTrans = transContext?.translations?.default;

  if (currentTrans === null && defaultTrans === null) {
    return <Element suppressHydrationWarning={true} dangerouslySetInnerHTML={{ __html: '' }}></Element>;
  }
  const fallbackTranslations = () => {
    // transContext?.getTranslationFile('en');
  };

  let string: any = currentTrans ? currentTrans[`${text}`] : null;

  if (!string) {
    fallbackTranslations();
  }
  if (replace) {
    replace.forEach((item) => {
      string = string?.replace(item.symbol, item.string);
    });
  }
  if (!string) {
    return `${text.replace(/_/g, ' ')}*`;
  }
  if (noSpan === false && string) {
    return <Element dangerouslySetInnerHTML={{ __html: string }}></Element>;
  }
  return string;
};

export default Translate;
