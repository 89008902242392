export interface ErrorInterface {
  status?: number;
  message: {
    message: string | [{ failed_rules: string[], field: string }];
    type: string;
  };
  name?: string;
}
export const ErrorHandler: any = function (this: any, message: ErrorInterface['message'], status: number) {
  this.message = message;
  this.status = status;

  if (Error.captureStackTrace) {
    Error.captureStackTrace(this, ErrorHandler);
  }
};

ErrorHandler.prototype = new Error() as unknown as ErrorInterface;
ErrorHandler.prototype.name = 'ErrorHandler';
