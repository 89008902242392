import {
  DefaultError,
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@/utils/@tanstack/react-query";

import { interpolateUrl } from "@/utils/common";
import type { ErrorInterface } from "@/utils/errorHandler";
import type { FetchDataInterface } from "@/utils/Global";
import { fetchData } from "@/utils/Global";

export type MutationFnProps<TPathParams> = {
  payload?: object;
  pathParams?: TPathParams;
};

export type UseMutationProps<TData> = {
  fetchDataOptions?: FetchDataInterface;
  options?: UseMutationOptions<TData, any, any, any>;
  select?: string;
  path: string;
  method?: string;
};

function useFetchMutation<
  TData,
  TPathParams extends { [key: string]: string } = { [key: string]: string },
>({
  fetchDataOptions,
  options,
  select,
  path,
  method = "POST",
}: UseMutationProps<TData>) {
  const queryClient = useQueryClient();
  const onError = (error: ErrorInterface) => {
    if (error && error.status === 401) {
      queryClient.removeQueries({ queryKey: ["init"], exact: true });
    }
  };
  const mutationFn = ({
    payload,
    pathParams,
  }: MutationFnProps<TPathParams>): Promise<TData> => {
    const composedPath = pathParams ? interpolateUrl(path, pathParams) : path;
    return fetchData({
      body: payload,
      path: composedPath,
      select,
      method,
      ...fetchDataOptions,
    });
  };

  return useMutation({
    mutationFn,
    onError: (error: ErrorInterface) => onError(error),
    ...options,
  });
}

export default useFetchMutation;
