/* eslint-disable react-hooks/rules-of-hooks */
import {
  DefaultOptions,
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from "@/utils/@tanstack/react-query";

import type { ErrorInterface } from "@/utils/errorHandler";
import {
  CustomDefaultOptions,
  fetchData,
  FetchDataInterface,
} from "@/utils/Global";
import type { DefaultError, QueryKey } from "@/utils/@tanstack/query-core";

function useFetchQuery<TQueryFnData>(
  queryKey: string,
  fetchOptions: FetchDataInterface | null = {},
  options?: {},
) {
  const queryClient = useQueryClient();
  const queryType = fetchOptions?.queryType ? fetchOptions.queryType : null;
  const defaultOptions: CustomDefaultOptions = queryClient.getDefaultOptions();

  const onError = (error: ErrorInterface) => {
    if (error && error.status === 401) {
      queryClient.removeQueries({ queryKey: ["init"], exact: true });
    }
  };
  const updatedFetchOptions = fetchOptions;

  const queryOptions: any = {
    onError: (error: ErrorInterface) => onError(error),
    ...options,
  };

  let queryKeyObject: any = { queryKey: [queryKey] };
  if (updatedFetchOptions) {
    queryKeyObject = { ...queryKeyObject, ...updatedFetchOptions };
    //queryKeyObject.push(updatedFetchOptions);
  }
  return useQuery<TQueryFnData, DefaultError, TQueryFnData, QueryKey>({
    ...queryKeyObject,
    ...queryOptions,
    queryFn: () =>
      fetchData({
        path: queryKey,
        ...fetchOptions,
        locale: defaultOptions.locale,
      }),
    enabled: queryType === null ? queryOptions.enabled : false,
  });
}

export default useFetchQuery;
