import type { UseQueryResult } from "@/utils/@tanstack/react-query";
import { useQuery, useQueryClient } from "@/utils/@tanstack/react-query";
import useFetchQuery from "./useQuery";
import InitInterface from "@/types/initInterface";
import useFetchMutation from "./useMutation";
import LeaderboardInterface from "@/types/leaderboardInterface";
import Participant from "@/types/participant";
import FanInterface from "@/types/fandata";

export const useSetQuery = (key: string[], data: any) => {
  const queryClient = useQueryClient();
  queryClient.setQueryData(key, data);
};

export const useGetQuery = (queryKey: string[], data = false) => {
  return useQuery({ queryKey, queryFn: () => data }) as UseQueryResult;
};

export const useGetInit = () => {
  return useGetQuery(["init"]) as UseQueryResult<InitInterface>;
};

export const useInit = () => {
  return useFetchMutation<InitInterface>({
    path: "event/pin/{event_pin}",
    method: "GET",
  });
};
export const useFanData = ({ eventHash, participantCode, init }: { eventHash: string; participantCode: string, init: FanInterface }) => {
  return useFetchQuery<FanInterface>(`event/${eventHash}/participant/${participantCode}`, {}, { initialData: init, });
};

export const useInitHash = () => {
  return useFetchMutation<InitInterface>({
    path: "event/hash/{event_hash}",
    method: "GET",
  });
};

export const useGetLeaderboard = (activity: string, latest: null | { participant_id: number, updated: number }) => {
  let participant = latest ? `&participant=${latest.participant_id}` : "";
  return useFetchQuery<LeaderboardInterface>(
    `event/leaderboard/${activity}?limit=5${participant}`,
    null,
    {
      enabled: activity !== undefined,
      staleTime: 0,
    },
  );
};
export const useGetLeaderboardLatest = (activity: string) => {
  return useFetchQuery<{ participant_id: number, updated: number }>(
    `event/leaderboard/${activity}/lastupdated`,
    null,
    {
      enabled: activity !== undefined,
      refetchIntervalInBackground: true,
      staleTime: 0,
      refetchInterval: 10000,
    },
  );
};

export const useParticipantSearch = () => {
  return useFetchMutation<Participant, { participant_code: string }>({
    path: "event/participant/search/{participant_code}",
    method: "GET",
  });
};

export const useParticipantCreate = () => {
  return useFetchMutation<Participant>({
    path: "event/participant",
    method: "POST",
  });
};

export const useParticipantUpdate = () => {
  return useFetchMutation<Participant, { participant_id: string }>({
    path: `event/participant/{participant_id}`,
    method: "POST",
  });
};

export const useParticipantEmailUpdate = () => {
  return useFetchMutation<Participant, { participant_id: string }>({
    path: `event/participant/{participant_id}/email`,
    method: "POST",
  });
};
