"use client"
import React from 'react';

export type TranslationObject = {
  [key: string]: string
}

export type Translations = {
  current: TranslationObject | null;
  default: TranslationObject | null;
};

export type TranslationContextProps = {
  translations: Translations;
 // getTranslationFile: Function;
} | null;

const TranslationsContext = React.createContext<TranslationContextProps>(null);

const TranslateProvider = ({ children, translations }: { children: React.ReactNode; translations:TranslationObject }) => {
  const obj: Translations = {
    current: translations,
    default: null,
  };



  return (
    <TranslationsContext.Provider value={{ translations : obj }}>
      {children}
    </TranslationsContext.Provider>
  );
};

export { TranslationsContext };

export default TranslateProvider;
